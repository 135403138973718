
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "b8f8cded-fa66-4b1f-b537-77f20dc4a4af"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/usr/src/app/src/app.config.ts"

export default /* #__PURE__ */ defuFn(cfg0, inlineConfig)
